import { useQuery } from '@apollo/client';
import queryAuthorById from '@/lib/wp/authors/getAuthorById';
import styles from './BlockConversations.module.scss';

export default function AuthorList({ author }) {
  
  const query = queryAuthorById;
  const { loading, error, data } = useQuery(query, { variables: { id: author?.slug } });

  if (loading) {
    return (
      <div>
        Loading data for conversations...
      </div>
    );
  }

  if (error) {
    return <div>Error: {JSON.stringify(error.message)}</div>;
  }


  return (
    <li className={styles.author__list__author_bottom}>
      <div className={styles.author__list__author_inner}>
        <img className={styles.author__list__author_image} src={data?.user?.avatar?.url} alt={data?.user?.name} />
        <div>
          <div className={styles.author__list__author_name}>{data?.user?.name}</div>
          {data?.user?.social?.twitter && (
            <div className={styles.author__list__author_twitter}>@{data?.user?.social?.twitter}</div>
          )}
        </div>
      </div>
      <a className={styles.author__list__follow} href={`https://twitter.com/${data?.user?.social?.twitter}`} target="_blank">Follow</a>
    </li>
  );
}
