import { FaLinkedin, FaFacebook, FaRss, FaYoutube, FaTwitter } from 'react-icons/fa';
import styles from './SocialBar.module.scss';

/**
 * Render Footer
 *
 * @return {Element} SocialBar component
 */
export default function SocialBar() {
  const socialLinks = [
    { url: 'https://twitter.com/HDMmagazine', icon: <FaTwitter />, id: 'tw' },
    { url: 'https://www.facebook.com/HealthDataManagement/', icon: <FaFacebook />, id: 'fb' },
    { url: 'https://www.linkedin.com/company/health-data-management/about/', icon: <FaLinkedin />, id: 'in' },
  ];

  return (
    <div className={styles.socialBar}>
      <h3>Follow us:</h3>
      <ul>
        {socialLinks &&
          socialLinks.map((link) => (
            <li key={link.id}>
              <a target="_blank" href={link.url}>{link.icon}</a>
            </li>
          ))}
      </ul>
    </div>
  );
}
