import Link from 'next/link';
import cn from 'classnames';
import SocialBar from './SocialBar';
import styles from './Footer.module.scss';
import Cta from './Cta';
import NavLinks from './NavLinks';

const FooterLogo = () => (
  <div className={styles.footer__logo}>
    <p>HealthData</p>
    <p>Management</p>
  </div>
);

/**
 * Render Footer
 *
 * @return {Element} Footer component
 */
export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__inner}>
        <div className={styles.footer__left}>
          <FooterLogo />
          <Cta />
        </div>
        <div className={styles.footer__right}>
          <div className={styles.footer__right__inner}>
            <div className={styles.footer__right__navlinks}/>
            <div className={styles.footer__right__addressSocial}>
              <div className={styles.footer__address}>
                <h3><a href="/about-us">About Us</a></h3>
              </div>
              <div className={styles.footer__address}>
                <h3><a href="/partners">Partners</a></h3>
              </div>
              <div className={styles.footer__address}>
                <h3>Contact Us</h3>
                <p>3790 Fernandina Road</p>
                <p>Suite 301</p>
                <p>Columbia, SC 29210</p>
              </div>
              <SocialBar />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer__full}>
        <div className={styles.footer__full__inner}>
          <div className={styles.footer__section__legal}>
            <ul>
              <li>
                <Link href="/privacy-policy">
                  <a>Privacy Policy</a>
                </Link>
              </li>
              <li>&copy; 2021 HDM Group</li>
            </ul>
          </div>
          <div className={styles.footer__hdmGroupLogo}>
            <img
              className={styles.footer__hdmGroupLogo__mobile}
              src="/images/hdm-group-logo.png"
              alt="HDM Group Logo"
            />
            <a href="https://hdmgroup.org" target="_blank">
              <img
                className={styles.footer__hdmGroupLogo__mobile2}
                src="/images/hdm-group-logo-green.png"
                alt="HDM Group Logo"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
