/* eslint-disable arrow-body-style */
import Moment from 'react-moment';
import { useQuery } from '@apollo/client';
import queryConversations from '@/lib/wp/conversations/queryConversations';
import Container from '@/components/Container';
import { useCallback } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import AuthorList from './AuthorList';
import styles from './BlockConversations.module.scss';

/**
 * @param  props0
 * @param  props0.manualSelect
 * @param  props0.numberOfPosts
 * @param  props0.authors
 * @param  props0.selectedPosts
 */
export default function BlockConversations({ manualSelect, numberOfPosts, authors, selectedPosts }) {
  const query = queryConversations;
  const { loading, error, data } = useQuery(query, { variables: { first: numberOfPosts } });

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    loop: true,
    skipSnaps: false,
    inViewThreshold: 0.7,
    slidesToScroll: 1,
  });

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  if (loading) {
    return <div>Loading data for conversations...</div>;
  }

  if (error) {
    return <div>Error: {JSON.stringify(error.message)}</div>;
  }
  return (
    <div className={styles.conversation__outer_wrapper}>
      {window.location.pathname != '/conversations' ? (
        <Container>
          <div className={styles.conversations_title}>
            <h2>Conversation</h2>
            {/* <a href={`/conversations`}>See All</a> */}
          </div>
        </Container>
      ) : (
        <></>
      )}
      <Container>
        <Container className={styles.converations__inner}>
          <div className={styles.conversations__posts} ref={emblaRef}>
            <section className={styles.conversations__carousel}>
              {data?.conversations?.edges?.map((conversation, idx) => {
                return (
                  <div className={styles.conversation__post} key={idx}>
                    <div className={styles.conversation__heading}>
                      <img
                        className={styles.conversation__author_avatar}
                        src={conversation?.node?.conversations?.profilepicture?.sourceUrl}
                        alt={conversation?.node?.conversations?.author?.name}
                      />
                      <div>
                        <h3 className={styles.conversation__author_name}>
                          {' '}
                          {conversation?.node?.conversations?.byline}
                        </h3>
                        {conversation?.node?.conversations?.postUrl && (
                          <div className={styles.conversation__author_twitter}>
                            <a href={conversation?.node?.conversations?.postUrl} target="_blank" rel="noreferrer">
                              View Post{' '}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className={styles.conversation__content}>{conversation?.node?.conversations?.content}</p>
                    <Moment format="MMM DD YY" className={styles.conversation__date}>
                      {conversation?.node?.date}
                    </Moment>
                  </div>
                );
              })}
            </section>
            <section className={styles.converation__button_container}>
              <button className={styles.conversation__button} type="button" onClick={scrollPrev}>
                <img src="/images/left-arrow.svg" alt="left arrow" />
              </button>
              <button className={styles.conversation__button} type="button" onClick={scrollNext}>
                <img src="/images/right-arrow.svg" alt="right arrow" />
              </button>
            </section>
          </div>
          <div className={styles.author__list_wrapper}>
            <h3 className={styles.author__header}>Who we follow</h3>
            <ul className={styles.author__list}>
              {authors?.map((author) => {
                return <AuthorList author={author} />;
              })}
            </ul>
          </div>
        </Container>
      </Container>
    </div>
  );
}
