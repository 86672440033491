import globalPostFields from '@/lib/wp/_partials/globalPostFields';
import { gql } from '@apollo/client';

const queryAuthorById = gql`
  query GET_AUTHOR_BY_ID($id: ID!, $idType: UserNodeIdTypeEnum = SLUG) {
    user(id: $id, idType: $idType) {
      id
      name
      slug
      nickname
      social {
        twitter
        linkedin
      }
      avatar {
        url
      }
    }
  }
`;

export default queryAuthorById;
