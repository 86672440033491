import React, {useEffect} from 'react';
import styles from './Cta.module.scss';

/**
 * Render Footer
 *
 * @return {Element} Cta component
 */
export default function Cta() {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
    	if(window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "19543514",
          formId: "33d4b773-b96a-4319-8de0-cedff0980669",
          target: '#hubspotForm'
          });
      }
    });
  }, [])

  return (
    <div className={styles.cta}>
      <p>Sign up to get the latest stories sent to your inbox every week</p>
      <div id="hubspotForm"></div>
    </div>
  );
}
